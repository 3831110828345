import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Segeln in der Karibik" />
    <div className="container mx-auto  px-8">
      <div className="max-w-sm rounded overflow-hidden shadow-lg">
        {/* eslint-disable-next-line */}
        <a target="_blank"  href="https://www.amazon.de/gp/product/3892252912/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3892252912&linkCode=as2&tag=segelnkaribik-21&linkId=74c5176c896d41c36035bb58b2d78bbc">

          <img  border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=DE&ASIN=3892252912&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=segelnkaribik-21" alt="Segeln in der Karibik"/>

          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">Segeln in der Karibik</div>
            <p className="text-gray-700 text-base">
              Dieser bewährte nautische Reiseführer liegt nun in der fünften, völlig überarbeiteten Auflage vor: Das reizvolle karibische Segel- und Tauchparadies der Windward Islands von Martinique bis Grenada sowie Barbados und die im Süden gelegene Insel Tobago werden detailliert vorgestellt.
            </p>
          </div>
          {/*
          <div className="px-6 py-4">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#photography</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#travel</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">#winter</span>
          </div>
          */}
        </a>
      </div>
      
    </div>


      {/*
      <div className="w-full py-6">  

        <h3
        style={{ 
          margin: 0,
          fontFamily: `'Rufina', serif`,
          fontStyle: `italic`,
          fontSize: `54px`,
          lineHeight: `72px`,
          letterSpacing: `0px`,
        }}>Büchertips</h3>
        <a target="_blank"  href="https://www.amazon.de/gp/product/3892252912/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3892252912&linkCode=as2&tag=segelnkaribik-21&linkId=74c5176c896d41c36035bb58b2d78bbc">
          <img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=DE&ASIN=3892252912&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=segelnkaribik-21" alt="Segeln in der Karibik"/>
        </a>
        <img src="//ir-de.amazon-adsystem.com/e/ir?t=segelnkaribik-21&l=am2&o=3&a=3892252912" width="1" height="1" border="0" alt="" 
          style={{
            border: `none !important`,
            margin: `0px !important`,
            }}
          />
      </div>
      */}
  </Layout>
)
/*
<Link to="/page-2/">Go to page 2</Link>
<p>Welcome to your new Gatsby site.</p>
<p>Now go build something great.</p>
*/

export default IndexPage
